@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

:root {
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
  --dark: #404045;
  --light: #EEEEEE;
}

/* Base styles & title */
body {
  font-family: "Noto Serif";
  color: white;
  background-color: black;
}

body.dark-mode {
  color: var(--secondary);
  background-color: white;
}

.App {
  max-width: 960px;
  margin: 0 auto;
}

.title h1 {
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}

.title h2,
.title p {
  text-align: center;
}

.title h2 {
  margin-top: 60px;
  font-size: 2.6rem;
}

/* Upload form styles */
form {
  margin: 30px auto 10px;
  text-align: center;
}

label input {
  height: 0;
  width: 0;
  opacity: 0;
}

label {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}

label:hover {
  background: var(--primary);
  color: white;
}

.output {
  height: 60px;
  font-size: 0.8rem;
}

.error {
  color: var(--error);
}

/* Progress bar styles */
.progress-bar {
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* Image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

/* Footer styles */
footer {
  padding: 20px 0;
  text-align: center;
  color: white;
  margin-top: auto;
}

.footer-title {
  color: var(--secondary);
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 10px;
}

.version {
  display: inline-block;
  margin-right: 10px;
}

.footer-link {
  color: var(--primary);
  text-decoration: none;
}

.footer-link:hover {
  color: white;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  border-radius: 1rem;
  cursor: pointer;
  width: 60px;
  height: 30px;
  overflow: hidden;
}

.checkbox {
  opacity: 0;
}

.slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
  transition: 0.4s;
}

.slider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 40%;
  height: 80%;
  border-radius: 100%;
  transform: translateY(-50%);
  border: 4px solid var(--dark);
  background-color: var(--light);
  transition: 0.4s;
}

.slider::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 100%;
  transform: translate(40%, -20%);
  background-color: var(--dark);
  transition: 0.4s;
}

.checkbox:checked + .slider {
  background-color: var(--light);
}

.checkbox:checked + .slider::before {
  border: 4px solid var(--light);
  background-color: var(--dark);
  transform: translate(90%, -50%);
}

.checkbox:checked + .slider::after {
  width: 0;
  height: 0;
  background-color: var(--light);
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-title {
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
  cursor: pointer;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--secondary);
  border: 1px solid var(--primary);
  padding: 10px;
  border-radius: 5px;
}

.dropdown-item {
  padding: 5px;
  color: var(--primary);
  text-decoration: none; /* Remove underline */
}

.dropdown-item:hover {
  background-color: var(--primary);
  color: white;
}

.dropdown-item a {
  text-decoration: none;
  color: inherit;
}

.dropdown-item:hover a {
  color: white;
}

/* Dark mode toggle styles */
.dark-mode-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.dark-mode-toggle {
  display: inline-block;
  cursor: pointer;
}
